<script>
import { required, maxLength } from "vuelidate/lib/validators";
import validationMessages from "@/components/validations";
import Monitor from "@/services/Monitor";
import Swal from "sweetalert2";

export default {
  components: {validationMessages},

  props: ['tenantSetupData'],

  data() {
    return {
      tenant: "",
      competition: "",
      competition_type: "",
      active_season: null,
      tenant_text: "",
      primary: null,
      order: null,
      tenants_options: [],
      competition_options: [],
      competition_type_options: ["cup", "league"], // Hardcoded options
      showModal: false,
      submitted: false,
      tryingToSubmit: false,
      showLoader: false,
      csrf_token: localStorage.getItem("csrf_token"),
      error: null,
    };
  },

  validations: {
    competition: {
      required,
      maxLength: maxLength(8),
    },
  },

  watch: {
    tenantSetupData: {
      immediate: true,
      handler(newData) {
        if (newData) {
          this.competition = newData.tournament_id;
          this.competition_type = newData.competition_type;
          this.active_season = newData.active_season;
          this.primary = newData.primary;
          this.order = newData.order;
          this.tenant = newData.tenant_team_id; // Set tenant from prop
        }
      },
    },
  },

  methods: {
    closeModal() {
      this.showModal = false;
      this.resetForm();
    },

    async getTenants() {
      try {
        const response = await Monitor.getAllTenants();
        this.tenants_options = response.data.data.map(item => ({
          value: item.id,
          tenant: item.tenant, // Store tenant name separately
          text: `${item.tenant} - ${item.provider} (${item.team_type})`
        }));
      } catch (error) {
        console.error("Error fetching tenants:", error);
      }
    },

    async getAllTournament() {
      try {
        const response = await Monitor.getAllTournament();
        this.competition_options = response.data.data.map(item => ({
          value: item.tournament_id,
          text: `${item.competition_name} - ${item.tournament_season} (${item.provider})`,
        }));
      } catch (error) {
        this.error = error.response?.data?.error || "An error occurred";
        console.error("Error fetching tournaments:", this.error);
      }
    },

    async updateTenantTournament() {
      this.tryingToSubmit = true;
      this.submitted = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.tryingToSubmit = false;
        return;
      }

      // Find the tenant item from the selected value
      const tenantItem = this.tenants_options.find(item => item.value === this.tenant);

      // Check if tenantItem is found
      if (!tenantItem) {
        this.failedmsg("Invalid tenant selected.");
        this.tryingToSubmit = false;
        return;
      }

      let formData = {
        tenant_team_id: this.tenant,
        tenant: tenantItem.tenant, // Send only the tenant name
        tournament_id: this.competition,
        competition_type: this.competition_type,
        active_season: this.active_season,
        primary: this.primary,
        order: this.order,
      };

      try {
        const response = await Monitor.updateTenantTournament(formData);
        if (response.error) {
          this.failedmsg(response.error);
        } else {
          this.successmsg(response.message);
          this.closeModal();
          this.refreshData();
        }
      } catch (error) {
        this.error = error.response?.data?.error || "An error occurred";
        this.failedmsg(this.error);
      } finally {
        this.tryingToSubmit = false;
        this.refreshData();
      }
    },

    successmsg(message) {
      Swal.fire("Success", message, "success");
    },

    failedmsg(message) {
      Swal.fire("Error", message, "error");
    },

    refreshData() {
      this.$emit("onRefresh");
    },

    resetForm() {
      this.competition = "";
      this.competition_type = "";
      this.active_season = null;
      this.primary = null;
      this.order = null;
      this.$v.$reset();
    },

    modalShown() {
      this.getTenants();
      this.getAllTournament();
    }
  },

  mounted() {
    this.getTenants();
    this.getAllTournament();
  }
};
</script>

<template>
  <b-modal
      @shown="modalShown"
      id="edit_tenant_tournament"
      size="l"
      v-model="showModal"
      @hidden="resetForm"
      title="Edit Tenant Tournament"
      title-class="font-18"
  >
    <form @submit.prevent="updateTenantTournament">
      <div class="row">
        <div v-if="$v.$error" class="text-danger">Complete all fields</div>
        <b-card-text>
          <div class="row">
            <div class="col-sm-12">
              <b-form-group
                  label="Competitions/Tournaments"
                  label-for="formrow-competition-input"
                  class="mb-3"
              >
                <b-form-select
                    v-model="competition"
                    :options="competition_options"
                    :class="{ 'is-invalid': submitted && $v.competition.$error }"
                ></b-form-select>
                <validationMessages
                    v-if="submitted"
                    :fieldName="'Competitions/Tournaments'"
                    :validationName="$v.competition"
                ></validationMessages>
              </b-form-group>

              <b-form-group
                  label="Competition Type"
                  label-for="formrow-competition_type-input"
                  class="mb-3"
              >
                <b-form-select
                    v-model="competition_type"
                    :options="competition_type_options"
                ></b-form-select>
              </b-form-group>

              <b-form-group
                  label="Active Season"
                  label-for="formrow-active_season-input"
                  class="mb-3"
              >
                <b-form-select
                    v-model="active_season"
                    :options="[
                    { value: 1, text: '1' },
                    { value: 0, text: '0' }
                  ]"
                ></b-form-select>
              </b-form-group>

              <b-form-group
                  label="Primary"
                  label-for="formrow-primary-input"
                  class="mb-3"
              >
                <b-form-select
                    v-model="primary"
                    :options="[
                    { value: 1, text: '1' },
                    { value: 0, text: '0' }
                  ]"
                ></b-form-select>
              </b-form-group>

              <b-form-group label="Order" label-for="formrow-order-input" class="mb-3">
                <b-form-input v-model.trim="order" type="number"></b-form-input>
              </b-form-group>

              <input type="hidden" name="csrf_token" v-model="csrf_token"/>
            </div>
          </div>
        </b-card-text>
      </div>
    </form>

    <template v-slot:modal-footer>
      <b-button variant="light" @click="closeModal">Close</b-button>
      <b-button
          variant="primary"
          @click="updateTenantTournament"
          :disabled="tryingToSubmit || showLoader"
      >
        <b-spinner v-show="tryingToSubmit" small></b-spinner>
        Submit
      </b-button>
    </template>
  </b-modal>
</template>
